import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import {Swiper, SwiperSlide} from 'swiper/react'
import 'swiper/css'
import GameCard from './components/GameCard'
import games from './content/games'
import './css/fadeEdges.css'

function Games() {
  const theme = useTheme()
  const isBigScreen = useMediaQuery(theme.breakpoints.up('sm'))

  return (
        <>
            {isBigScreen ? (
                <Grid container spacing={2}>
                    {games.map((game, index) => (
                        <Grid item xs={6} md={3} key={index}>
                        <GameCard game={game} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Box className='fadeEdges'>
                    <Swiper
                        spaceBetween={16}
                        slidesPerView='auto'
                        centeredSlides={true}
                        grabCursor={true}
                    >
                        {games.map((game, index) => (
                            <SwiperSlide key={index} style={{width:'auto'}}>
                                <Box sx={{mb:1}}>
                                    <GameCard game={game}/>
                                </Box>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
            )}
        </>
    )
}

export default Games