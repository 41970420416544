const games = [
    {
        title: 'Oxidius',
        image: 'oxidius.jpg',
        description: `Oxidius is a table-top roleplaying game that emphasizes immersive character design and fast-paced, realistic combat scenarios. At oxidius.com, players can read through the rules and create characters for their campaigns.`,
        link: 'https://oxidius.com/'
    },
    {
        title: 'GRUFLE',
        image: 'grufle.png',
        description: `GRUFLE is a grid-based puzzle game where the goal is to slide pieces on to goal tiles. However, every piece slides one direction when clicked on, and a different direction when a different piece is clicked on. Players are scored based on how quickly they slide every piece onto a goal tile.`,
        link: 'https://grufle.com/'
    },
    {
        title: 'Life Dots',
        image: 'lifedots.png',
        description: `Life Dots is an evolution simulator in which independent agents perceive and interact with their environment. Dots can move, consume food and each other, grow, and reproduce. Each dot's behavior is determined by a neural network which evolves genetically with the rest of the dot's traits.`,
        link: 'https://www.lifedots.net'
    },
    {
        title: 'Shrine of the Lily',
        image: 'shrineofthelily.jpg',
        description: `Shrine of the Lily is a two player board game in which players take turns moving their pieces around the board, with the goal of moving their lily piece to the center tile for three turns. Each of the seven types of pieces moves differently and invokes different effects.`,
        link: 'https://github.com/ethanprihar/ShrineOfTheLily'
    }
];

export default games;
