import React, {useState} from 'react'
import {Routes, Route, useNavigate, useLocation} from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import MenuIcon from '@mui/icons-material/Menu'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'

import Welcome from './pages/Welcome'
import Employment from './pages/Employment'
import Publications from './pages/Publications'
import Games from './pages/Games'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound'

import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'

const drawerWidth = 240
const pathToPageName = {
  '/': 'Welcome',
  '/employment/': 'Employment',
  '/publications/': 'Publications',
  '/games/': 'Games',
  '/contact/': 'Contact'
}

function Navigation() {

    const theme = useTheme()
    const navigate = useNavigate()
    const location = useLocation()
    const currentPath = location.pathname
    const pageName = pathToPageName[currentPath] || ''

    const isWiderThanMd = useMediaQuery(theme.breakpoints.up('md'))
    const isPhonePortrait = useMediaQuery('(max-width:600px) and (orientation:portrait)')
    const isPhoneLandscape = useMediaQuery('(max-width:600px) and (orientation:landscape)')
    const appBarHeight = isPhonePortrait ? 56 : isPhoneLandscape ? 48 : 64

    const [open, setOpen] = useState(false)
    const [filterAnchorEl, setFilterAnchorEl] = useState(null)
    const [selectedTags, setSelectedTags] = useState({type:[], field:[], venue:[], keywords:[]})

    const handleDrawerToggle = () => {
        setOpen(!open)
    }

    const handleItemClick = (path) => () => {
        navigate(path)
        if (!isWiderThanMd) {
            handleDrawerToggle()
        }
    }

    const handleFilterClick = (event) => {
        setFilterAnchorEl(event.currentTarget)
    }

    const handleFilterClose = () => {
        setFilterAnchorEl(null)
    }

    const handleTagToggle = (category, value) => {
        setSelectedTags((prev) => ({
        ...prev,
        [category]: prev[category].includes(value)
            ? prev[category].filter((tag) => tag !== value)
            : [...prev[category], value]
        }))
    }

    return (
        <Box>
            <AppBar position='fixed'>
                <Toolbar>
                    {isWiderThanMd ? (
                        Object.entries(pathToPageName).map(([path, name], i) => (
                            <Button
                                key={i}
                                color='inherit'
                                onClick={handleItemClick(path)}
                                sx={{textTransform:'none'}}
                            >
                                <Typography
                                    variant='h6'
                                    sx={{
                                        position: 'relative',
                                        paddingBottom: '0px',
                                        '&::after': {
                                            content: '""',
                                            position: 'absolute',
                                            width: '100%',
                                            height: '2.5px',
                                            bottom: 0,
                                            left: 0,
                                            backgroundColor: name === pageName ? 'currentColor' : 'transparent',
                                            transition: 'background-color 0.3s ease'
                                        }
                                    }}
                                >
                                    {name}
                                </Typography>
                            </Button>
                        ))
                    ):(
                        <IconButton
                            size='large'
                            edge='start'
                            color='inherit'
                            aria-label='menu'
                            onClick={handleDrawerToggle}
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    color: 'inherit'
                                }
                            }}
                        >
                            <MenuIcon sx={{mr:1}}/>
                            <Typography variant='h6'>{pageName}</Typography>
                        </IconButton>
                    )}
                    {(currentPath === '/employment/' || currentPath === '/publications/') && (
                        <IconButton
                            edge='end'
                            color='inherit'
                            size='large'
                            onClick={handleFilterClick}
                            sx={{ ml: 'auto' }}
                        >
                        <FilterAltIcon fontSize='inherit'/>
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>

            <Drawer
                container={() => document.body}
                variant='temporary'
                open={open}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }}
                sx={{'& .MuiDrawer-paper':{boxSizing:'border-box', width:drawerWidth}}}
            >
                <Box role='presentation' onClick={handleDrawerToggle}>
                    <List>
                        {Object.entries(pathToPageName).map(([path, name], i) => (
                            <ListItem key={i} disablePadding>
                                <ListItemButton onClick={handleItemClick(path)}>
                                    <ListItemText primary={name}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>

            <Box sx={{padding:2, marginTop:`${appBarHeight}px`}}>
                <Routes>
                    <Route path='/' element={<Welcome/>}/>
                    <Route
                        path='/employment/'
                        element={
                            <Employment
                                filterAnchorEl={filterAnchorEl}
                                onFilterClose={handleFilterClose}
                                selectedTags={selectedTags}
                                onTagToggle={handleTagToggle}
                            />
                        }
                    />
                    <Route
                        path='/publications/'
                        element={
                            <Publications
                                filterAnchorEl={filterAnchorEl}
                                onFilterClose={handleFilterClose}
                                selectedTags={selectedTags}
                                onTagToggle={handleTagToggle}
                            />
                        }
                    />
                    <Route path='/games/' element={<Games/>}/>
                    <Route path='/contact/' element={<Contact/>}/>
                    <Route path='*' element={<NotFound/>}/>
                </Routes>
            </Box>
        </Box>
    )
}

export default Navigation