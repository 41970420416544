const jobs = [
    {
        id: 1,
        title: 'Lead AI Scientist',
        company: 'Coworked, Inc.',
        location: 'Boston, Massachusetts',
        dateRange: 'August 2024 - Present',
        description: [
            'Developed data and machine learning infrastructure.',
            'Designed algorithms for project planning, execution, and risk analysis.',
        ],
        type: 'Full-time',
        field: 'Artificial Intelligence'
    },
    {
        id: 2,
        title: 'AI Consultant',
        company: 'Coworked, Inc.',
        location: 'Boston, Massachusetts',
        dateRange: 'February 2024 - August 2024',
        description: [
            'Researched initial product design approaches.',
            'Assisted in project management assistant development.'
        ],
        type: 'Consulting',
        field: 'Artificial Intelligence'
    },
    {
        id: 3,
        title: 'AI/Machine Learning Lead Advisor',
        company: 'Mindsets Learning, Inc.',
        location: 'Boston, Massachusetts',
        dateRange: 'October 2023 - August 2024',
        description: [
            'Developed a process for automatic grading of open-response questions with retrieval augmented generation.',
        ],
        type: 'Consulting',
        field: 'Artificial Intelligence'
    },
    {
        id: 4,
        title: 'Postdoctoral Researcher',
        company: '\u00C9cole Polytechnique F\u00E9d\u00E9rale de Lausanne',
        location: 'Lausanne, Switzerland',
        dateRange: 'September 2023 - August 2024',
        description: [
            'Designed and trained reinforcement learning agents to behave like high-performing students.',
            'Developed methodology to generate personalized feedback for students by combining reinforcement learning and large language models.',
            'Implemented and evaluated personalized feedback within open-ended learning environments.',
            'Advised various student projects.'
        ],
        type: 'Full-time',
        field: 'Artificial Intelligence'
    },
    {
        id: 5,
        title: 'Music Room Instructor',
        company: 'Boys and Girls Club',
        location: 'Worcester, Massachusetts',
        dateRange: 'February 2023 - May 2023',
        description: [
            'Provided beginner-level music lessons in piano, drums, and guitar.',
            'Recorded and produced members\' original songs.'
        ],
        type: 'Part-time',
        field: 'Education'
    },
    {
        id: 6,
        title: 'Research Assistant',
        company: 'Worcester Polytechnic Institute',
        location: 'Worcester, Massachusetts',
        dateRange: 'July 2019 - July 2023',
        description: [
            'Created novel reinforcement learning algorithms for statistically reliable content recommendation.',
            'Created infrastructure to recommend tutoring to thousands of users in real-time.',
            'Created novel machine learning algorithms to model student behavior.',
            'Developed algorithms for improving causal inference with machine learning.',
            'Created and evaluated methods for generating educational content with large language models.',
            'Aggregated datasets for the learning and data science communities.',
            'Advised various student projects.'
        ],
        type: 'Part-time',
        field: 'Artificial Intelligence'
    },
    {
        id: 7,
        title: 'Lead Designer',
        company: 'Blomp Labs, LLC',
        location: 'Vernon, Connecticut',
        dateRange: 'June 2018 - December 2018',
        description: [
            'Designed aquaponics kits for education.'
        ],
        type: 'Consulting',
        field: 'Product Design'
    },
    {
        id: 8,
        title: 'Machine Learning Engineer',
        company: 'Pratt & Whitney',
        location: 'East Hartford, Connecticut',
        dateRange: 'April 2018 - July 2019',
        description: [
            'Designed deep learning models of jet engine behavior.',
            'Developed machine learning algorithms to anticipate jet engine malfunctions.'
        ],
        type: 'Full-time',
        field: 'Artificial Intelligence'
    },
    {
        id: 9,
        title: 'Controls Engineer',
        company: 'Pratt & Whitney',
        location: 'East Hartford, Connecticut',
        dateRange: 'January 2017 - April 2018',
        description: [
            'Designed algorithms for jet engine control.',
            'Implemented flight control software.',
            'Developed test cases for control algorithms.'
        ],
        type: 'Full-time',
        field: 'Controls Engineering'
    },
    {
        id: 10,
        title: 'Student Assistant',
        company: 'Worcester Polytechnic Institute',
        location: 'Worcester, Massachusetts',
        dateRange: 'August 2016 - October 2016',
        description: [
            'Created test materials.',
            'Reviewed student work.',
            'Supported student comprehension.'
        ],
        type: 'Part-time',
        field: 'Education'
    },
    {
        id: 11,
        title: 'Beam Controls Summer Intern',
        company: 'Northrop Grumman',
        location: 'Redondo Beach, California',
        dateRange: 'May 2016 - July 2016',
        description: [
            'Designed program validation and test plans.',
            'Created support hardware and analysis software.'
        ],
        type: 'Internship',
        field: 'Controls Engineering'
    },
    {
        id: 12,
        title: 'Prototype Designer',
        company: 'Tribal Engineering',
        location: 'Long Beach, California',
        dateRange: 'July 2015 - July 2016',
        description: [
            'Designed a product to maximize interconnectivity in the home.',
            'Assembled prototypes through modeling and additive manufacturing.'
        ],
        type: 'Consulting',
        field: 'Product Design'
    },
    {
        id: 13,
        title: 'Beam Controls Summer Intern',
        company: 'Northrop Grumman',
        location: 'Redondo Beach, California',
        dateRange: 'May 2015 - July 2015',
        description: [
            'Designed cable routing and design schema for laser optics.'
        ],
        type: 'Internship',
        field: 'Controls Engineering'
    },
    {
        id: 14,
        title: 'Wilderness Survival Teacher',
        company: 'University of Hartford',
        location: 'West Hartford, Connecticut',
        dateRange: 'July 2014 - August 2014',
        description: [
            'Developed wilderness survival curriculum.',
            'Implemented lessons on edible plants, fire-making, and shelter construction.',
            'Designed and led teamwork exercises among camp members.'
        ],
        type: 'Part-time',
        field: 'Education'
    },
    {
        id: 15,
        title: 'Robotics Teacher',
        company: 'Solomon Schechter Day School',
        location: 'West Hartford, Connecticut',
        dateRange: 'January 2013 - May 2013',
        description: [
            'Developed robotics curriculum.',
            'Guided students through introductory robotics.'
        ],
        type: 'Part-time',
        field: 'Education'
    }
];

export default jobs;
