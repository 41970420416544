import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

function Contact() {

    const theme = useTheme();

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess(false);
        try {
            const response = await fetch('https://jcop2k0ei8.execute-api.us-east-1.amazonaws.com/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email, message}),
            });

            if (response.ok) {
                setSuccess(true);
                setEmail('');
                setMessage('');
            } else {
                throw new Error('Failed to send message');
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccess(false);
        setError('');
    };

    return (
        <Container sx={{
            paddingTop: theme.spacing(4),
            maxWidth: 'md',
            textAlign: 'center'
        }}>
            <Typography variant='h4' gutterBottom>
                Contact Form
            </Typography>
            <Typography variant='body1' gutterBottom>
                Please fill out the form below and I will reply shortly.
            </Typography>
            <Box 
                component="form" 
                onSubmit={handleSubmit} 
                sx={{ 
                    mt: 3, 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center' 
                }}
            >
                <Box maxWidth="sm" sx={{ width: '100%' }}>
                    <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        sx={{mb:2}}
                    />
                    <TextField
                        fullWidth
                        label="Message"
                        variant="outlined"
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        sx={{mb:2}}
                    />
                </Box>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <Button type="submit" variant="contained" color="primary">
                        <Typography variant='body2'>Send Message</Typography>
                    </Button>
                )}
            </Box>
            <Snackbar 
                open={success} 
                autoHideDuration={6000} 
                onClose={handleClose}
                anchorOrigin={{vertical:'bottom', horizontal:'center'}}
            >
                <Alert onClose={handleClose} severity="success" sx={{width:'100%'}}>
                    Message sent successfully!
                </Alert>
            </Snackbar>
            <Snackbar 
                open={Boolean(error)} 
                autoHideDuration={6000} 
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleClose} severity="error" sx={{width:'100%'}}>
                    {error}
                </Alert>
            </Snackbar>
        </Container>
    );
}

export default Contact;