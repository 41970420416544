import {createTheme} from '@mui/material/styles';
import {grey} from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#F9AE37', // Orange
    },
    secondary: {
      main: '#9E9E9E', // Grey
    },
    background: {
      default: '#FFF8E1', // Almost white with a hint of orange
      paper: '#FFFBF0', // Almost white with a hint of orange for paper elements
    },
    text: {
      primary: '#100000', // Black for primary text
      secondary: grey[800], // Medium grey for secondary text
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', // You can customize the font family here
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#FFF8E1', // Apply the background color to the body
        },
      },
    },
  },
});

export default theme;