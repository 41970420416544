import React from 'react'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import useTheme from '@mui/material/styles/useTheme'

const venues = ['AIED', 'EDM', 'L@S', 'UMAP', 'LAK', 'JEDM', 'NeurIPS', 'Turbo Expo', 'NIME']
const types = ['Journal', 'Full', 'Short', 'Poster', 'Workshop', 'Consortium']
const keywords = ["Reinforcement Learning", "Large Language Models", "Deep Learning", "Personalization", "User Modeling", "Anomaly Detection", "Causal Inference", "Statistical Analysis", "A/B Testing", "Crowdsourcing", "Robotics"]
const PubFilter = ({filterAnchorEl, onFilterClose, selectedTags, onTagToggle}) => {
    
    const theme = useTheme()

    return (
        <Dialog
            open={Boolean(filterAnchorEl)}
            onClose={onFilterClose}
            maxWidth='sm'
            fullWidth
            sx={{
                '& .MuiDialog-paper':{
                    maxWidth:theme.breakpoints.values.sm,
                    margin:'0 auto'
                }
            }}
        >
            <DialogTitle sx={{paddingBottom:0}}>
                Publication Filters
                <IconButton
                    onClick={onFilterClose}
                    sx={{
                        position:'absolute',
                        right:8,
                        top:8,
                        color:(theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                
                <Typography variant='subtitle1' sx={{mt:2}}>Venue</Typography>
                {venues.map((venue) => (
                    <Chip
                        key={venue}
                        label={venue}
                        clickable
                        color={selectedTags.venue.includes(venue) ? 'primary' : 'default'}
                        onClick={() => onTagToggle('venue', venue)}
                        sx={{mr:1, mt:1}}
                    />
                ))}

                <Typography variant='subtitle1' sx={{mt:2}}>Type</Typography>
                {types.map((type) => (
                    <Chip
                        key={type}
                        label={type}
                        clickable
                        color={selectedTags.type.includes(type) ? 'primary' : 'default'}
                        onClick={() => onTagToggle('type', type)}
                        sx={{mr:1, mt:1}}
                    />
                ))}

                <Typography variant='subtitle1' sx={{mt:2}}>Keywords</Typography>
                {keywords.map((keyword) => (
                    <Chip
                        key={keyword}
                        label={keyword}
                        clickable
                        color={selectedTags.keywords.includes(keyword) ? 'primary' :'default'}
                        onClick={() => onTagToggle('keywords', keyword)}
                        sx={{mr:1, mt:1}}
                    />
                ))}
            </DialogContent>
        </Dialog>
    )
}

export default PubFilter
