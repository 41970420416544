import * as React from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import {BrowserRouter as Router} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Navigation from './components/Navigation';

import theme from './theme';

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Router>
        <Navigation/>
      </Router>
    </ThemeProvider>
  );
}