import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import useTheme from '@mui/material/styles/useTheme'

const JobDetails = ({job, onClose}) => {

    const theme = useTheme()

    return (
        <Dialog
            open={Boolean(job)}
            onClose={onClose}
            maxWidth='sm'
            fullWidth
            sx={{
                '& .MuiDialog-paper':{
                    maxWidth:theme.breakpoints.values.sm,
                    margin:'0 auto'
                }
            }}
        >
            <DialogTitle sx={{position:'relative', paddingRight:'50px', paddingBottom:theme.spacing(1)}}>
                <Typography variant="h6">
                    {job.title}
                </Typography>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position:'absolute',
                        right:theme.spacing(1),
                        top:theme.spacing(1),
                        color:(theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography variant='h7' sx={{fontStyle:'italic'}}>{job.company}</Typography>
                <Typography variant='body2' color='text.secondary'>{job.location}</Typography>
                <Typography variant='body2' color='text.secondary'>{job.dateRange}</Typography>
                <ul style={{ paddingLeft:'0', marginTop:'0', listStylePosition:'inside' }}>
                    {job.description.map((item, index) => (
                        <li key={index} style={{ marginTop:'4px', display:'flex', alignItems:'flex-start' }}>
                            <span style={{ marginRight:'8px', lineHeight:'1.5' }}>•</span>
                            <span style={{ flex:1 }}>{item}</span>
                        </li>
                    ))}
                </ul>
            </DialogContent>
        </Dialog>
    )
}

export default JobDetails
