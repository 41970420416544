import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'

const JobCard = ({job, onClick}) => {
    
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Card
            onClick={() => onClick(job)}
            sx={{
                cursor:'pointer',
                maxWidth:theme.breakpoints.values.md,
                margin:'0 auto',
                mb:2
            }}
        >
            <CardContent>
                {isSmallScreen ? (
                    <Box>
                        <Typography variant='h6'>{job.title}</Typography>
                        <Typography variant='h6' sx={{fontStyle:'italic'}}>{job.company}</Typography>
                        <Typography variant='body2' color='text.secondary'>{job.dateRange}</Typography>
                    </Box>
                ):(
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Box sx={{pt:1}}>
                            <Typography variant='h6'>
                                {job.title}, <span style={{fontStyle:'italic'}}>{job.company}</span>
                            </Typography>
                        </Box>
                        <Box sx={{pt:1, flexShrink:0, ml:2, width:'200px', textAlign:'right'}}>
                            <Typography variant='body2' color='text.secondary'>
                                {job.dateRange}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    )
}

export default JobCard
