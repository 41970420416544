import React from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import SchoolIcon from '@mui/icons-material/School'
import useTheme from '@mui/material/styles/useTheme'

function Welcome() {

  const theme = useTheme()

    return (
        <Container maxWidth='sm' style={{textAlign:'center', marginTop:theme.spacing(1)}}>
            <img
                src='profile.png'
                style={{width:200, height:200, display:'block', margin:'0 auto'}}
            />
            <Box mt={2}>
                <Typography variant='h4' component='h1' gutterBottom>
                    Hi, I'm Ethan.
                </Typography>
                <Typography variant='body1' paragraph>
                    I'm the Lead AI Scientist at Coworked, where we are developing an autonomous AI project manager to augment project professionals. My background includes various consulting roles, postdoctoral research in Machine Learning at École Polytechnique Fédérale de Lausanne, and a PhD in Data Science from Worcester Polytechnic Institute.
                </Typography>
                <Typography variant='body1' paragraph>
                    My professional career has focused on automating human tasks, and my academic research has focused on personalized learning, both using a combination of reinforcement learning, user modeling, causal inference, and large language models.
                </Typography>
                <Typography variant='body1' paragraph>
                    Outside of work, I enjoy game development, pottery, music, and foraging.
                </Typography>
                <Box mt={-1}>
                    <IconButton href='https://scholar.google.com/citations?user=QIr4dxAAAAAJ' target='_blank' rel='noopener' size='large'>
                        <SchoolIcon fontSize='inherit'/>
                    </IconButton>
                    <IconButton href='https://www.linkedin.com/in/ethan-prihar' target='_blank' rel='noopener' size='large'>
                        <LinkedInIcon fontSize='inherit'/>
                    </IconButton>
                </Box>
            </Box>
        </Container>
    )
}

export default Welcome