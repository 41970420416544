import React from 'react'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import useTheme from '@mui/material/styles/useTheme'

const types = ['Full-time', 'Part-time', 'Consulting', 'Internship']
const fields = ['Artificial Intelligence', 'Controls Engineering', 'Education', 'Product Design']

const JobFilter = ({filterAnchorEl, onFilterClose, selectedTags, onTagToggle}) => {
    
    const theme = useTheme()

    return (
        <Dialog
            open={Boolean(filterAnchorEl)}
            onClose={onFilterClose}
            maxWidth='sm'
            fullWidth
            sx={{
                '& .MuiDialog-paper':{
                    maxWidth:theme.breakpoints.values.sm,
                    margin:'0 auto'
                }
            }}
        >
            <DialogTitle sx={{paddingBottom:0}}>
                Employment Filters
                <IconButton
                    onClick={onFilterClose}
                    sx={{
                        position:'absolute',
                        right:8,
                        top:8,
                        color:(theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                
                <Typography variant='subtitle1' sx={{mt:2}}>Type</Typography>
                {types.map((type) => (
                    <Chip
                        key={type}
                        label={type}
                        clickable
                        color={selectedTags.type.includes(type) ? 'primary' : 'default'}
                        onClick={() => onTagToggle('type', type)}
                        sx={{mr:1, mt:1}}
                    />
                ))}

                <Typography variant='subtitle1' sx={{mt:2}}>Field</Typography>
                {fields.map((field) => (
                    <Chip
                        key={field}
                        label={field}
                        clickable
                        color={selectedTags.field.includes(field) ? 'primary' : 'default'}
                        onClick={() => onTagToggle('field', field)}
                        sx={{mr:1, mt:1}}
                    />
                ))}
            </DialogContent>
        </Dialog>
    )
}

export default JobFilter
