import React, {useState} from 'react'
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import PubCard from './components/PubCard'
import PubDetails from './components/PubDetails'
import PubFilter from './components/PubFilter'
import pubs from './content/pubs'

function Publications({filterAnchorEl, onFilterClose, selectedTags, onTagToggle}) {

    const theme = useTheme()

    const [selectedPub, setSelectedPub] = useState(null)

    const handleCardClick = (pub) => {
        setSelectedPub(pub)
    }

    const handleModalClose = () => {
        setSelectedPub(null)
    }

    const isPubVisible = (pub) => {
        return Object.entries(selectedTags).every(
        ([category, values]) =>
            values.length === 0 || 
            (category === 'keywords' ? values.some(value => pub[category].includes(value)) : values.includes(pub[category]))
        )
    }

    return (
        <Box>
            <PubFilter
                filterAnchorEl={filterAnchorEl}
                onFilterClose={onFilterClose}
                selectedTags={selectedTags}
                onTagToggle={onTagToggle}
            />
            <Box>
                {pubs.filter(isPubVisible).map((pub, index) => (
                    <PubCard key={index} pub={pub} onClick={handleCardClick}/>
                ))}
            </Box>
            {selectedPub && (
                <PubDetails pub={selectedPub} onClose={handleModalClose}/>
            )}
        </Box>
    )
}

export default Publications
