import React from 'react'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import useTheme from '@mui/material/styles/useTheme'
import Link from '@mui/material/Link'

const PubDetails = ({pub, onClose}) => {

    const theme = useTheme()

    const sanitizeTitle = (title) => {
        return title.replace(/[\/:?\*<>|"'']/g, '');
    }

    return (
        <Dialog
            open={Boolean(pub)}
            onClose={onClose}
            maxWidth='sm'
            fullWidth
            sx={{
                '& .MuiDialog-paper':{
                    maxWidth:theme.breakpoints.values.sm,
                    margin:'0 auto'
                }
            }}
        >
            <DialogTitle sx={{position:'relative', paddingRight:'50px', paddingBottom:theme.spacing(1)}}>
                <Typography variant="h6">
                    {pub.title}
                </Typography>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position:'absolute',
                        right:theme.spacing(1),
                        top:theme.spacing(1),
                        color:(theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography variant='h7'>{pub.authors}</Typography>
                <Typography variant='body2' color='text.secondary' sx={{fontStyle:'italic'}}>{pub.full_venue}, {pub.year}</Typography>
                <div style={{
                    display:'flex', flexWrap:'wrap', alignItems:'center', marginTop:theme.spacing(1)}}>
                    <Link
                        href={`${process.env.PUBLIC_URL}/publications/${sanitizeTitle(pub.title)}.pdf`}
                        target="_blank"
                        rel="noopener"
                        sx={{marginRight:theme.spacing(1), display:'flex', alignItems:'center'}}
                    >
                        <OpenInNewIcon sx={{marginBottom:theme.spacing(1)}}/>
                    </Link>
                    <div style={{
                        width:'1px',
                        height:'24px',
                        backgroundColor:theme.palette.grey[400],
                        marginRight:theme.spacing(1),
                        marginBottom:theme.spacing(1)
                    }}></div>
                    <Chip
                        label={`${pub.type} Paper`}
                        sx={{marginRight:theme.spacing(1), marginBottom:theme.spacing(1), backgroundColor:theme.palette.grey[300]}}
                    />
                    {pub.awards.length > 0 && pub.awards.map((award, index) => (
                        <Chip
                            key={index}
                            label={award}
                            sx={{marginRight:theme.spacing(1), marginBottom:theme.spacing(1), backgroundColor:theme.palette.primary.main}}
                        />
                    ))}
                </div>
                <Typography variant='body1'>{pub.abstract}</Typography>
            </DialogContent>
        </Dialog>
    )
}

export default PubDetails