import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import IconButton from '@mui/material/IconButton'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

function GameCard({ game }) {
    
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <Card sx={{
            display:'flex',
            flexDirection:'column',
            height:'100%',
            width:isSmallScreen ? '250px' : 'auto'
        }}>
            <CardHeader
                title={
                    <Typography 
                        variant='h6' 
                        noWrap
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {game.title}
                    </Typography>
                }
                action={
                    <IconButton
                        onClick={() => window.open(game.link, '_blank')}
                        sx={{color:theme.palette.primary.main, marginRight:theme.spacing(1)}}
                    >
                        <OpenInNewIcon/>
                    </IconButton>
                }
                sx={{paddingRight:theme.spacing(1)}}
            />
            <CardActionArea 
                component='a' 
                href={game.link} 
                target='_blank' 
                rel='noopener noreferrer'
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start'
                }}
            >
                <CardMedia
                    component='img'
                    image={`${process.env.PUBLIC_URL}/games/${game.image}`}
                    alt={game.title}
                    sx={{
                        width: '90%',
                        height: 'auto',
                        aspectRatio: '1 / 1'
                    }}
                />
            </CardActionArea>
            <CardContent>
                <Typography variant='body2' color='text.secondary'>
                    {game.description}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default GameCard
