import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import useTheme from '@mui/material/styles/useTheme'

const PubCard = ({pub, onClick}) => {
    
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Card
            onClick={() => onClick(pub)}
            sx={{
                cursor:'pointer',
                maxWidth:theme.breakpoints.values.md,
                margin:'0 auto',
                mb:2
            }}
        >
            <CardContent>
                {isSmallScreen ? (
                    <Box>
                        <Typography variant='h6'>{pub.title}</Typography>
                        <Typography variant='body2' color='text.secondary'>{pub.venue}, {pub.year}</Typography>
                    </Box>
                ):(
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Box sx={{pt:1, flexGrow:1, minWidth:0}}>
                            <Typography variant='h6'>
                                {pub.title}
                            </Typography>
                        </Box>
                        <Box sx={{pt:1, flexShrink:0, ml:2, width:'120px', textAlign:'right'}}>
                            <Typography variant='body2' color='text.secondary'>
                                {pub.venue}, {pub.year}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </CardContent>
        </Card>
    )
}

export default PubCard
