import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const NotFound = () => {
    return (
        <Box sx={{ textAlign: 'center', padding: 4 }}>
            <Typography variant='h4'>404</Typography>
            <Typography variant='h6'>Page Not Found</Typography>
        </Box>
    )
}

export default NotFound
