import React, {useState} from 'react'
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import JobCard from './components/JobCard'
import JobDetails from './components/JobDetails'
import JobFilter from './components/JobFilter'
import jobs from './content/jobs'

function Employment({filterAnchorEl, onFilterClose, selectedTags, onTagToggle}) {

    const theme = useTheme()

    const [selectedJob, setSelectedJob] = useState(null)

    const handleCardClick = (job) => {
        setSelectedJob(job)
    }

    const handleModalClose = () => {
        setSelectedJob(null)
    }

    const isJobVisible = (job) => {
        return Object.entries(selectedTags).every(
        ([category, values]) =>
            values.length === 0 || values.includes(job[category])
        )
    }

    return (
        <Box>
            <JobFilter
                filterAnchorEl={filterAnchorEl}
                onFilterClose={onFilterClose}
                selectedTags={selectedTags}
                onTagToggle={onTagToggle}
            />
            <Box>
                {jobs.filter(isJobVisible).map((job, index) => (
                    <JobCard key={index} job={job} onClick={handleCardClick}/>
                ))}
            </Box>
            {selectedJob && (
                <JobDetails job={selectedJob} onClose={handleModalClose}/>
            )}
        </Box>
    )
}

export default Employment
